<template>
  <div :class="$style.info" @click="$emit('click')">
    <ui-avatar
      size="custom"
      :src="$auth.account.avatar"
      :class="$style.avatar"
      :border="$auth.account.styles.avatarBorderColor"
      :sticker="$auth.account.styles.sticker"
    />
    <div :class="$style.nickname">
      <ui-username
        :class="$style.text"
        :color="$auth.account.styles.usernameColor"
        >{{ $auth.account.username }}</ui-username
      >
    </div>
    <div :class="$style.clan" v-if="false">
      <div :class="$style.text">[AG]</div>
    </div>
    <img src="~/assets/images/back.svg?url" :class="$style.icon" alt="" />
  </div>
</template>

<script>
import UiAvatar from '~/components/ui/avatar'
import UiUsername from '~/components/ui/username'

export default {
  name: 'LayoutMobileMenuAccountInfo',
  components: {
    UiUsername,
    UiAvatar
  },
  emits: ['click']
}
</script>

<style module lang="scss">
.info {
  display: flex;
  align-items: center;
}
.avatar {
  width: 2.4em;
  height: 2.4em;
  margin-right: 1.2em;
}
.nickname {
  .text {
    font-weight: 500;
    font-size: 1.8em;
    line-height: 144%;
    color: #ffffff;
  }
}
.clan {
  margin-left: 0.8em;
  align-self: flex-start;
  .text {
    font-size: 1.2em;
    line-height: 167%;
    color: #26ff85;
  }
}
.icon {
  transform: rotate(-90deg);
  margin-left: auto;
}
</style>
