<template>
  <div :class="$style.container">
    <ui-link :class="$style.link" :to="`/profile/${$auth.account.id}`">
      <div :class="$style.text">{{ t('myProfile') }}</div>
    </ui-link>
    <ui-link to="/m/account" :class="$style.link">
      <div :class="$style.text">{{ t('stats') }}</div>
    </ui-link>

    <div :class="$style.divider"></div>

    <ui-link
      v-for="item in menu"
      :key="item.id"
      :to="item.path"
      exact
      :class="$style.link"
    >
      <div :class="$style.iconWrapper">
        <ui-icon :name="item.icon" :class="$style.icon" />
      </div>
      <div :class="$style.main">
        <div :class="$style.text">{{ t(`${item.id}.title`) }}</div>
        <div :class="$style.info" v-if="te(`${item.id}.description`)">
          <div :class="$style.text">{{ t(`${item.id}.description`) }}</div>
        </div>
      </div>
    </ui-link>
  </div>
</template>

<i18n>
{
  "en": {
    "myProfile": "My profile",
    "stats": "Stats",
    "logout": "Logout",
    "settings": {
      "title": "Profile settings",
      "description": "Avatar, nickname etc."
    },
    "steam": {
      "title": "Task settings"
    },
    "prizes": {
      "title": "Your prizes",
      "description": "Yours by right"
    },
    "referrals": {
      "title": "Referrals",
      "description": "Invite friends",
      "invited": "Invited {count} friends"
    }
  },
  "ru": {
    "myProfile": "Мой профиль",
    "stats": "Статистика",
    "logout": "Выйти из аккаунта",
    "backToProfile": "Вернуться в профиль",
    "settings": {
      "title": "Настройки профиля",
      "description": "Аватарка, никнейм и другое"
    },
    "steam": {
      "title": "Настройки заданий"
    },
    "prizes": {
      "title": "Ваши призы",
      "description": "То, что ваше по праву"
    },
    "referrals": {
      "title": "Реферальная программа",
      "description": "Пригласи друга — получи коины!",
      "invited": "Пригласи друга — получи коины!"
    }
  }
}
</i18n>

<script setup>
import { useSettingsStore } from '~/stores/common/settings'
import UiLink from '~/components/ui/link'
import UiIcon from '@/components/ui/icon/index.vue'

const { te, t } = useI18n()
const settingsStore = useSettingsStore()
const referralSystem = computed(
  () => settingsStore.data?.referralSystem || false
)
const menu = computed(() =>
  [
    {
      id: 'settings',
      path: '/profile/settings',
      icon: 'settings-gear',
      enable: true
    },
    {
      id: 'steam',
      path: '/profile/settings/task',
      icon: 'steam',
      enable: true
    },
    // {
    //   id: "prizes",
    //   path: "/profile/settings/prizes",
    //   enable: true
    // },
    {
      id: 'referrals',
      path: '/profile/settings/referrals',
      icon: 'users',
      enable: referralSystem.value
    }
  ].filter(item => item.enable)
)
</script>

<style lang="scss" module>
.container {
  padding-left: 3.6em;
}
.link {
  display: flex;
  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4em;
    line-height: 171%;
    color: #ffffff;
  }
}
.link + .link {
  margin-top: 2em;
}
.divider {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin: 2em 0;
  background: #36394c;
}
.link {
  display: flex;
  border-radius: 0.4em;
  .iconWrapper {
    width: 1.6em;
    height: 1.6em;
    margin-right: 0.8em;
    margin-top: 0.3em;
    .icon {
      width: 100%;
      fill: #ffffff;
      color: #ffffff;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    .text {
      font-size: 1.4em;
      letter-spacing: -0.02em;
      color: #ffffff;
    }
  }
  .info {
    .text {
      font-size: 1em;
      line-height: 130%;
      color: #acb0d5;
    }
  }
}
</style>
