<template>
  <div :class="$style.container" v-if="$auth.account">
    <layout-mobile-menu-account-info
      :class="$style.info"
      @click="toggleSettings"
    />
    <layout-mobile-menu-account-coins :class="$style.coins" />
    <ui-collapse :value="showMenu">
      <layout-mobile-menu-account-settings :class="$style.settings" />
    </ui-collapse>
  </div>
</template>

<script>
import LayoutMobileMenuAccountInfo from '~/components/layout/mobile/menu/account/info'
import LayoutMobileMenuAccountCoins from '~/components/layout/mobile/menu/account/coins'
import LayoutMobileMenuAccountSettings from '~/components/layout/mobile/menu/account/settings'
import UiCollapse from '~/components/ui/collapse'
export default {
  name: 'LayoutMobileMenuAccount',
  components: {
    UiCollapse,
    LayoutMobileMenuAccountSettings,
    LayoutMobileMenuAccountCoins,
    LayoutMobileMenuAccountInfo
  },
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    toggleSettings() {
      this.showMenu = !this.showMenu
    }
  }
}
</script>

<style module lang="scss">
.container {
  padding: 1.6em 3.2em 1.6em 1.6em;
}
.info {
  cursor: pointer;
}
.coins,
.settings {
  margin-top: 2em;
}
</style>
