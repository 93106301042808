<template>
  <nav class="header-menu-content">
    <ui-link
      v-for="item in menuItems"
      :key="item.id"
      class="header-menu-content__link"
      :to="item.path"
      :data-menu-id="item.id"
      v-auth-required="{ enabled: item.auth }"
      v-analytics.click.amplitude="[
        'navigation_click',
        {
          navigation_name: item.id,
          navigation_text: t(item.translate)
        }
      ]"
    >
      <ui-icon
        :name="item.icon"
        v-if="item.icon"
        class="header-menu-content__icon"
      />
      <span class="header-menu-content__text">
        {{ t(item.translate) }}
      </span>
    </ui-link>
  </nav>
</template>

<i18n src="~/assets/translates/menu.json" />

<script setup>
import UiLink from '~/components/ui/link'
import { useMainStore } from '@/stores/common/main'
import UiIcon from '@/components/ui/icon/index.vue'

const mainStore = useMainStore()
const { t } = useI18n()
const { $auth } = useNuxtApp()

const menuItems = computed(() =>
  mainStore.menu.map(item => ({
    ...item,
    path:
      item.path === 'profile'
        ? $auth.account
          ? `/profile/${$auth.account.id}`
          : '/profile'
        : item.path,
    disabled: item.disabled || (item.auth && !$auth.account)
  }))
)
</script>

<style lang="scss" module></style>
