<template>
  <div class="locale-container">
    <ui-link
      v-for="locale in locales"
      :key="locale.code"
      :href="locale.href"
      :class="[
        'locale-item',
        locale.active && 'locale-item_active',
        locale.active && 'active'
      ]"
      target="_self"
    >
      <span class="locale-item__text">{{ locale.name }}</span>
    </ui-link>
  </div>
</template>

<script>
import UiLink from '~/components/ui/link'

export default {
  name: 'CommonLocale',
  components: {
    UiLink
  },
  computed: {
    locales() {
      return this.$i18n.locales.map(locale => ({
        code: locale.code,
        name: locale.name,
        active: locale.code === this.$i18n.locale,
        href: this.$normalizePath(this.$route.path, { locale: locale.code })
      }))
    }
  }
}
</script>

<style module lang="scss"></style>
