<template>
  <div :class="$style.container">
    <div :class="$style.coin" v-for="coin in coins" :key="coin.type">
      <div :class="$style.heading">
        <div :class="$style.text">{{ coin.name }} {{ t('Coin') }}</div>
        <span></span>
      </div>
      <ui-coin
        :value="coin.value"
        :code="coin.type"
        :class-value="$style.coinValue"
      />
    </div>
    <div :class="$style.divider"></div>
  </div>
</template>

<i18n>
{
  "ru": {
    "Coin": "Коин"
  }
}
</i18n>

<script setup>
import UiCoin from '~/components/ui/coin'
import { useSettingsStore } from '~/stores/common/settings'
import { useAccountStore } from '@/stores/account'

const { t } = useI18n()
const { $auth } = useNuxtApp()
const settingsStore = useSettingsStore()
const accountStore = useAccountStore()

const coins = computed(() => {
  const coins = settingsStore.data?.coins || []
  return coins.map(coin => ({
    type: coin.code,
    value: accountStore.balance(coin.code),
    name: coin.name.toUpperCase()
  }))
})
</script>

<style module lang="scss">
.container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding: 0.6em 0.1em 0.3em;
}
.divider {
  position: absolute;
  left: 45%;
  top: 0.6em;
  bottom: 0.6em;
  margin: 0 1.2em;
}
.coin {
  width: 40%;
}
.heading {
  display: flex;
  margin-bottom: 0.4em;
  font-family: var(--primary-font);
  font-style: var(--primary-font-style);
  .text {
    font-weight: 700;
    font-size: 1em;
    line-height: 1.4;
    letter-spacing: -0.02em;
    color: var(--app-color-gray);
  }
  span {
    margin-left: 0.7em;
    font-weight: 500;
    font-size: 1em;
    line-height: 140%;
    color: #26ff85;
  }
}

.coinValue {
  font-size: 1.6em !important;
}
</style>
