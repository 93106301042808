<template>
  <div :class="$style.container">
    <div :class="$style.coins" v-if="$auth.account" id="header-account-coins">
      <div v-for="coin in coins" :key="coin.code" :class="$style.coin">
        <div :class="$style.title">
          <span :class="$style.text">{{ coin.name }} {{ t('Coin') }}</span>
        </div>
        <ui-coin :code="coin.type" :value="coin.value" />
      </div>
    </div>
    <div :class="$style.account">
      <ui-spinner v-if="loadingAccount" size="3" />
      <div
        :class="$style.link"
        v-else-if="$auth.account"
        @click="tabsStore.updateActiveTab('account')"
      >
        <ui-avatar
          size="small"
          :src="$auth.account.avatar"
          :class="$style.avatar"
          :sticker="$auth.account.styles.sticker"
          :border="$auth.account.styles.avatarBorderColor"
        />
        <ui-username
          :class="$style.username"
          :color="$auth.account.styles.usernameColor"
        >
          {{ $auth.account.username }}
        </ui-username>
      </div>
      <div
        :class="$style.link"
        v-else
        @click="$sdk.module('auth').then(({ login }) => login())"
        v-analytics.click.gtm="[
          'landing',
          'registration',
          'registration_link_button_click'
        ]"
      >
        <ui-avatar size="small" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "ru": {
    "Coin": "Коин"
  }
}
</i18n>

<script setup>
import UiSpinner from '~/components/ui/spinner'
import UiAvatar from '~/components/ui/avatar'
import UiCoin from '~/components/ui/coin'
import UiUsername from '~/components/ui/username'
import { useTabsStore } from '@/stores/common/tabs.js'
import { useSettingsStore } from '~/stores/common/settings'
import { useAccountStore } from '@/stores/account'
import UiButton from '~/components/ui/button/index.vue'

const { t } = useI18n()
const { $sdk } = useNuxtApp()
const tabsStore = useTabsStore()
const settingsStore = useSettingsStore()
const accountStore = useAccountStore()

const loadingAccount = ref(true)
const activeId = computed(() => tabsStore.activeTabId)
const coins = computed(() => {
  const coins = settingsStore.data?.coins || []
  return coins.map(coin => ({
    type: coin.code,
    value: accountStore.balance(coin.code),
    name: coin.name.toUpperCase()
  }))
})

onMounted(async () => {
  await $sdk.module('auth').then(({ getAccount }) => getAccount())
  loadingAccount.value = false
})
</script>

<style lang="scss" module>
.container {
  display: flex;
  justify-content: flex-end;
  width: var(--app-aside-width);
}
.coins {
  flex: 1 1;
  padding: 1.9em 2em 1.2em 1.1em;
  display: flex;
  align-items: flex-start;
  @include down(md) {
    display: none;
  }
}
.coin {
  flex: 1 1;
  padding: 0 0.8em 0 1.1em;
}
//.coin:first-child {
//  background: var(--t-app-l3-bg);
//  border-radius: 0.6em;
//}
.title {
  color: var(--app-color-gray);
  text-transform: uppercase;
  margin-bottom: 0.2em;
  font-family: var(--primary-font);
  font-style: var(--primary-font-style);
  font-weight: 700;
  white-space: nowrap;

  .text {
    font-weight: 500;
    font-size: 1em;
    line-height: 140%;
    letter-spacing: -0.02em;
  }
}
.account {
  flex: 0 0 9em;
  width: 9em;
  padding: 0.8em 1.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--t-app-div-color);
  overflow: hidden;
  background-color: var(--t-app-l2-bg);
  user-select: none;
}
.link {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.avatar {
  margin-bottom: 0.4em;
}
.username {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 5em;
  font-size: 1.4em;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-family: var(--primary-font);
  font-style: var(--primary-font-style);
  font-weight: 700;
  color: var(--app-color-text);
}
</style>
