<template>
  <div :class="$style.container">
    <common-socials />
    <common-locale />
  </div>
</template>

<script>
export default {
  name: 'LayoutMobileMenuFooter'
}
</script>

<script setup>
import CommonLocale from '~/components/common/locale'
import CommonSocials from '~/components/common/socials'
</script>

<style module lang="scss">
.container {
  display: flex;
  justify-content: space-between;
  padding: 1.6em;
}
.link {
  img {
    width: 3em;
    height: 3em;
  }
}
</style>
