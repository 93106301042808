import { defineStore } from 'pinia'
import { useNotificationsStore } from '~/stores/common/notifications'

export const useTabsStore = defineStore('tabs', {
  state: () => ({
    activeTabId: ''
  }),
  getters: {
    tabs(state) {
      // const { t } = useI18n({ useScope: 'global' })
      const t = useNuxtApp().$i18n.t
      const notificationsStore = useNotificationsStore()
      const tabs = [
        {
          id: 'account',
          group: 0,
          component: 'layout-tabs-account',
          hidden: true,
          auth: true
        },
        // {
        //   id: 'bonus',
        //   title: t('tab.bonus'),
        //   group: 0,
        //   icon: 'bonus',
        //   component: 'layout-tabs-bonus',
        //   hidden: false,
        //   hiddenInMobMenu: true,
        //   auth: true
        // },
        {
          id: 'code',
          title: t('tab.code'),
          group: 0,
          icon: 'codes',
          component: 'layout-tabs-code',
          hidden: false,
          auth: false
        },
        {
          id: 'notify',
          title: t('tab.notify'),
          group: 0,
          icon: 'notify',
          component: 'layout-tabs-notify',
          hidden: false,
          counter: notificationsStore.unread,
          auth: true
        },
        // { id: 'chat', title: t('tab.chat'), group: 0, icon: 'chat', component: 'layout-tabs-chat', hidden: false, auth: false },
        {
          id: 'purchases',
          title: t('tab.purchases'),
          group: 0,
          icon: 'gifts',
          component: 'layout-tabs-purchases',
          hidden: false,
          auth: true
        },
        {
          id: 'clan',
          title: t('tab.clan'),
          group: 0,
          icon: 'group',
          component: 'layout-tabs-clan',
          hidden: false,
          auth: true
        },
        {
          id: 'pickem',
          title: t('tab.pickem'),
          group: 1,
          icon: 'pickem',
          path: '/pickem',
          hidden: false,
          auth: true,
          gtm: ['paripass', 'navigation', 'profile_pickem']
        },
        {
          id: 'leaderboard',
          title: t('tab.leaderboard'),
          group: 1,
          icon: 'leaderboard',
          component: 'layout-tabs-leaderboard',
          hidden: false,
          auth: false
        },
        {
          id: 'news',
          title: t('tab.news'),
          group: 1,
          icon: 'news',
          component: 'layout-tabs-news',
          hidden: false,
          auth: false
        }
      ]
      return tabs.map(tab => {
        tab.active = tab.id === state.activeTabId
        return tab
      })
    },
    activeTab(state) {
      return this.tabs.find(item => item.id === state.activeTabId)
    },
    tabGroups() {
      return this.tabs.reduce((groups, item) => {
        groups[item.group] = groups[item.group] || []
        groups[item.group].push(item)
        return groups
      }, [])
    }
  },
  actions: {
    updateActiveTab(tabId) {
      this.activeTabId = tabId
    }
  }
})
