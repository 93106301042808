<template>
  <layout-tabs-wrap>
    <template #title>{{ t('title') }}</template>
    <template #subtitle>{{ t('subtitle') }}</template>
    <app-leaders wrap />
  </layout-tabs-wrap>
</template>

<i18n>
{
  "en": {
    "title": "TOP Players",
    "subtitle": "Leaderboard is updated every 5 minutes"
  },
  "ru": {
    "title": "ТОП Игроков",
    "subtitle": "Лидерборд обновляется раз в 5 минут"
  }
}
</i18n>

<script>
export default {
  name: 'LayoutTabsLeaderboard'
}
</script>

<script setup>
import AppLeaders from '~/components/leaders'
import LayoutTabsWrap from '~/components/layout/tabs/wrap'
import { useAccountStore } from '@/stores/account'

const accountStore = useAccountStore()

await useAsyncData(async () => accountStore.fetchAccountRanks(), {
  server: false
})
const { t } = useI18n()
</script>

<style lang="scss" module>
.header {
  .text {
    font-weight: 700;
    font-size: 1.8em;
    line-height: 144%;
    text-align: center;
    letter-spacing: -0.02em;
  }
}
</style>
