<template>
  <div
    :class="$style.container"
    @click="$sdk.module('auth').then(({ logout }) => logout())"
  >
    <ui-icon name="logout" />
    <div :class="$style.title">{{ t('Logout') }}</div>
  </div>
</template>

<i18n>
{
  "ru": {
    "Logout": "Выйти из аккаунта"
  }
}
</i18n>

<script setup>
import UiIcon from '~/components/ui/icon/index.vue'

defineOptions({
  name: 'LayoutMobileMenuLogout'
})

const { t } = useI18n()
</script>

<style module lang="scss">
.container {
  display: flex;
  align-items: center;
  padding: 0.8em 1.6em;
  color: #ff898a;
  svg {
    width: 2em;
    height: 2em;
    margin-right: 1.6em;
  }
}
.title {
  font-size: 1.6em;
  line-height: 150%;
}
</style>
