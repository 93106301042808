<template>
  <nav :class="$style.container">
    <div :class="$style.group" v-for="(group, i) in tabs" :key="i">
      <component
        v-for="item in group"
        :key="item.id"
        :is="item.path ? UiLink : 'a'"
        :id="`tab-${item.id}`"
        :class="[$style.item, item.active && $style.active]"
        :to="item.path"
        @click="onClick(item)"
        v-auth-required="{ enabled: item.auth }"
        v-analytics.click="{
          gtm: item.gtm,
          amplitude: [
            'navigation_click',
            {
              navigation_name: item.id,
              navigation_text: item.title
            }
          ]
        }"
      >
        <ui-icon :class="$style.icon" :name="item.icon" />
        <span :class="$style.text">{{ item.title }}</span>
        <span :class="$style.counter" v-if="item.counter"></span>
      </component>
    </div>
    <div :class="$style.footer">
      <div :class="$style.group" id="aside-footer-menu">
        <ui-link
          :href="mainStore.supportUrl"
          :class="$style.item"
          v-analytics.click="{
            gtm: ['paripass', 'navigation', 'help'],
            amplitude: [
              'navigation_click',
              {
                navigation_name: 'support',
                navigation_text: t('Support')
              }
            ]
          }"
        >
          <ui-icon name="support" :class="$style.icon" />
          <span :class="$style.text">{{ t('Support') }}</span>
        </ui-link>
        <ui-link
          to="/faq"
          :class="$style.item"
          v-analytics.click="{
            gtm: ['paripass', 'navigation', 'faq'],
            amplitude: [
              'navigation_click',
              {
                navigation_name: 'faq',
                navigation_text: 'FAQ'
              }
            ]
          }"
        >
          <ui-icon name="news" :class="$style.icon" />
          <span :class="$style.text">FAQ</span>
        </ui-link>
      </div>
    </div>
  </nav>
</template>

<i18n>
{
  "ru": {
    "Support": "Помощь"
  }
}
</i18n>

<script setup>
import { useTabsStore } from '@/stores/common/tabs.js'
import { useMainStore } from '@/stores/common/main.js'
import UiLink from '~/components/ui/link'
import UiIcon from '@/components/ui/icon/index.vue'

defineProps({
  items: {
    type: Array,
    default: () => []
  }
})

const { t } = useI18n()
const tabsStore = useTabsStore()
const mainStore = useMainStore()

const groups = computed(() => tabsStore.tabGroups)
const tabs = computed(() =>
  groups.value.map(group => group.filter(tab => !tab.hidden))
)

const onClick = item => {
  if (!item.path) {
    tabsStore.updateActiveTab(item.id)
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding-top: 0.5em;
}
.group {
  width: 100%;
  padding: 1.6em 1.3em;
}
.group + .group {
  padding: 1.8em 1.3em 1.6em 1.3em;
  border-top: 1px solid var(--t-app-div-color);
}
.item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--app-color-text);
  cursor: pointer;
  font-weight: 500;
  line-height: 2em;
  padding: 0.8em 0;
  user-select: none;
  opacity: 0.4;
}
.item + .item {
  margin-top: 1.4em;
}
.disabled {
  pointer-events: none;
  opacity: 0.3;
}
:global(#tab-pickem) {
  &:global(.router-link-exact-active) {
    rect {
      fill: var(--t-app-logout);
    }
  }
}
:global(.router-link-exact-active),
.active {
  opacity: 1;
}
.text {
  display: block;
  text-align: center;
  font-size: 1.4em;
  line-height: 1.4285;
  font-family: var(--primary-font);
  font-style: var(--primary-font-style);
  font-weight: 700;
  letter-spacing: -0.02em;
}
.icon {
  margin-bottom: 0.4em;
  width: 2em;
  height: 2em;
}
.footer {
  display: flex;
  align-items: flex-end;
  flex: 1 1;
  padding-bottom: 0.5em;
  height: 100%;
}
.counter {
  background: #72ff9d;
  border-radius: 50%;
  height: 1em;
  width: 1em;
  display: flex;
  align-items: center;
  position: absolute;
  top: 10%;
  right: 30%;
}
</style>
