<template>
  <div :class="$style.container">
    <transition :name="$style.slide">
      <div :class="$style.body" v-if="mobileMenuStateOpen">
        <layout-mobile-menu-header @close="close" />
        <div :class="$style.scroll" v-scroll-lock>
          <layout-mobile-menu-account />
          <div :class="$style.divider" />
          <layout-mobile-menu-navigate />
          <div :class="$style.divider" />
          <layout-mobile-menu-market />
          <div :class="$style.divider" />
          <layout-mobile-menu-logout v-if="$auth.account" />
          <layout-mobile-menu-footer />
        </div>
      </div>
    </transition>
    <transition :name="$style.fade">
      <div
        :class="$style.overlay"
        v-if="mobileMenuStateOpen"
        @click="close"
      ></div>
    </transition>
  </div>
</template>

<script setup>
import LayoutMobileMenuHeader from '~/components/layout/mobile/menu/header'
import LayoutMobileMenuNavigate from '~/components/layout/mobile/menu/navigate'
import LayoutMobileMenuMarket from '~/components/layout/mobile/menu/market'
import LayoutMobileMenuLogout from '~/components/layout/mobile/menu/logout'
import LayoutMobileMenuAccount from '~/components/layout/mobile/menu/account'
import LayoutMobileMenuFooter from '~/components/layout/mobile/menu/footer'
import { useMainStore } from '@/stores/common/main.js'

const mainStore = useMainStore()
const $route = useRoute()
const mobileMenuStateOpen = computed(() => mainStore.mobileMenuStateOpen)
const close = () => mainStore.setMobileMenuStateOpen(false)
const fullPath = computed(() => $route.fullPath)

watch(fullPath, close)
</script>

<style lang="scss" module>
.container {
}
.body {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 80%;
  background-color: var(--t-app-bg);
  z-index: 61;
  overflow: hidden;
}
.divider {
  margin: 1.2em 0;
  width: 100%;
  height: 1px;
  background: #36394c;
}
.scroll {
  @include hide-scrollbar;
  height: calc(100% - 6.4em);
  overflow: auto;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 60;
  backdrop-filter: blur(24px);
  background: rgba(28, 28, 28, 0.4);
}
.slide {
  &:global(-enter-active),
  &:global(-leave-active) {
    transition: transform 0.4s;
  }

  &:global(-leave-to),
  &:global(-enter-from) {
    transform: translate(-100%, 0);
  }
}
.fade {
  &:global(-enter-active),
  &:global(-leave-active) {
    transition: opacity 0.3s ease;
  }

  &:global(-leave-to),
  &:global(-enter-from) {
    opacity: 0;
  }
}
</style>
