<template>
  <nav :class="$style.container">
    <div :class="$style.group" v-for="(group, i) in tabs" :key="i">
      <ui-link
        v-for="item in group"
        :key="item.id"
        :to="item.path || `/m/${item.id}`"
        :class="[$style.item, item.active && $style.active]"
        @click="onClick($event, item)"
        v-auth-required="{ enabled: item.auth }"
        v-analytics.click="{
          gtm: item.gtm,
          amplitude: [
            'navigation_click',
            {
              navigation_name: item.id,
              navigation_text: item.title
            }
          ]
        }"
      >
        <ui-icon :name="item.icon" :class="$style.icon" />
        <span :class="$style.text">{{ item.title }}</span>
        <span :class="$style.counter" v-if="item.counter"></span>
      </ui-link>
      <div v-if="i + 1 !== tabs.length" :class="$style.divider"></div>
    </div>
    <div :class="$style.divider"></div>
    <div :class="$style.footer">
      <ui-link
        :href="mainStore.supportUrl"
        :class="$style.item"
        v-analytics.click="{
          gtm: ['paripass', 'navigation', 'help'],
          amplitude: [
            'navigation_click',
            {
              navigation_name: 'support',
              navigation_text: t('Support')
            }
          ]
        }"
      >
        <ui-icon name="chat" :class="$style.icon" />
        <span :class="$style.text">{{ t('Support') }}</span>
      </ui-link>
      <ui-link
        to="/faq"
        :class="$style.item"
        v-analytics.click="{
          gtm: ['paripass', 'navigation', 'faq'],
          amplitude: [
            'navigation_click',
            {
              navigation_name: 'faq',
              navigation_text: 'FAQ'
            }
          ]
        }"
      >
        <ui-icon name="news" :class="$style.icon" />
        <span :class="$style.text">FAQ</span>
      </ui-link>
      <ui-link to="/rules" :class="$style.item">
        <ui-icon name="news" :class="$style.icon" />
        <span :class="$style.text">{{ t('Contest rules') }}</span>
      </ui-link>
    </div>
  </nav>
</template>

<i18n>
{
  "ru": {
    "Support": "Поддержка",
    "Contest rules": "Правила конкурса"
  }
}
</i18n>

<script setup>
import UiLink from '~/components/ui/link'
import { useTabsStore } from '@/stores/common/tabs.js'
import { useMainStore } from '@/stores/common/main.js'
import UiIcon from '@/components/ui/icon/index.vue'
import { computed } from 'vue'

const { t } = useI18n()
const tabsStore = useTabsStore()
const mainStore = useMainStore()
const { $auth, $router, $normalizePath } = useNuxtApp()

const tabs = computed(() =>
  tabsStore.tabGroups.map(group =>
    group.filter(tab => !tab.hidden && !tab.hiddenInMobMenu)
  )
)

const onClick = async (event, item) => {
  if (!item.path) {
    tabsStore.updateActiveTab(item.id)
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
}
.group {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1.6em;
}
.group + .group {
  padding-top: 0;
}
.divider {
  width: 100%;
  height: 1px;
  background: #36394c;
  margin: 2em 0;
}
.item {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: #41435c;
  @include down(sm) {
    color: #acb0d5;
  }

  svg {
    width: 2em;
    height: auto;
  }

  .text {
    font-weight: 500;
    font-size: 1.6em;
    line-height: 150%;
    color: #ffffff;
  }
}
.item + .item {
  margin-top: 2em;
}
.disabled {
  pointer-events: none;
  opacity: 0.3;
}
.active {
  color: #fff;
}
.text {
  display: block;
  text-align: center;
  font-size: 1.4em;
}
.icon {
  margin-right: 1.2em;
}
.footer {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 0 1.6em 0.8em;
  .item {
    color: #41435c;
    .text {
      font-weight: 500;
      font-size: 1.6em;
      line-height: 125%;
    }
  }
}
</style>
