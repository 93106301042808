<template>
  <div :class="$style.container">
    <div :class="$style.burger" @click="$emit('close')">
      <ui-icon name="menu" />
    </div>
    <common-logo />
  </div>
</template>

<script>
import CommonLogo from '~/components/common/logo'
import UiIcon from '~/components/ui/icon/index.vue'

export default {
  name: 'LayoutMobileMenuHeader',
  components: { CommonLogo, UiIcon },
  emits: ['close']
}
</script>

<style module lang="scss">
.container {
  display: flex;
  height: 6.4em;
  padding: 1.6em;
  align-items: center;
  .burger {
    cursor: pointer;
    margin-right: 4.8em;
    @include up(lg) {
      display: none;
    }
    svg {
      width: 3.2em;
      height: 3.2em;
      color: white;
    }
  }
}
</style>
