<template>
  <div :class="$style.container">
    <ui-link
      v-for="social in list"
      :class="$style.link"
      :key="social.name"
      :href="social.link"
    >
      <img
        :src="$matchMedia.down('sm') ? social.iconMobile : social.icon"
        alt=""
      />
    </ui-link>
  </div>
</template>

<script setup>
import UiLink from '~/components/ui/link'
import { useMainStore } from '@/stores/common/main.js'

const { locale } = useI18n()
const { $matchMedia } = useNuxtApp()
const mainStore = useMainStore()
const list = computed(() =>
  mainStore.socials.filter(item => item.locale.includes(locale.value))
)
</script>

<style module lang="scss">
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  :not(:last-child) {
    margin-right: 1em;
  }
}
.link {
  width: 3em;
  height: 3em;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
