<template>
  <nav :class="$style.container">
    <ui-link
      v-for="item in menuItems"
      :key="item.translate"
      :class="$style.link"
      :to="item.path"
      v-auth-required="{ enabled: item.auth }"
      v-analytics.click.amplitude="[
        'navigation_click',
        {
          navigation_name: item.id,
          navigation_text: t(item.translate)
        }
      ]"
    >
      {{ t(item.translate) }}
    </ui-link>
  </nav>
</template>

<i18n src="~/assets/translates/menu.json" />

<script setup>
import UiLink from '~/components/ui/link'
import { useMainStore } from '@/stores/common/main'

const mainStore = useMainStore()
const { t } = useI18n()
const { $auth, $appClient, $router, $normalizePath } = useNuxtApp()
const menuItems = computed(() =>
  mainStore.menu.map(item => ({
    ...item,
    path:
      item.path === 'profile'
        ? $auth.account
          ? `/profile/${$auth.account.id}`
          : '/profile'
        : item.path,
    disabled: item.disabled || (item.auth && !$auth.account)
  }))
)
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-gap: 2.4em;
  padding: 1.6em;
}
.link {
  text-transform: uppercase;
  font-size: 2em;
  line-height: 120%;
  color: #ffffff;
  font-family: var(--primary-font), sans-serif;
  font-weight: 700;
  font-style: var(--primary-font-style);
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
